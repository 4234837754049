/* Imports */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

:root {
  --clr--burgundy--hover: #61192e;
  --clr--burgundy: #822440;
  --clr--purple: #662482;
  --clr--pink: #f7a1eb;
  --ff--common: "Inter", sans-serif;
}

.bg-pink-purple-gradient {
  background: rgb(85, 50, 130);
  background: -moz-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553282",endColorstr="#be3b7e",GradientType=1);
}

.home_testimonials_bg {
  background: rgb(85, 50, 130);
  background: -moz-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553282",endColorstr="#be3b7e",GradientType=1);
  padding: 50px 0;
}

.txt-header-purple {
  font-family: var(--ff--common);
  color: var(--clr--purple);
  font-size: 32px;
  font-weight: 600;
}

.txt-header-white {
  font-family: var(--ff--common);
  color: white;
  font-size: 32px;
  font-weight: 600;
}

.txt-description-black-bold {
  font-family: var(--ff--common);
  color: black;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.txt-description-black {
  font-family: var(--ff--common);
  color: black;
  font-size: 18px;
  line-height: 24px;
}

.btn-burgundy {
  background-color: var(--clr--burgundy);
  border-radius: 10px;
  padding: 15px 25px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--ff--common);
  border: solid 2px var(--clr--burgundy);
  cursor: pointer;
}

.btn-burgundy:hover {
  background-color: var(--clr--burgundy--hover);
  border: solid 2px var(--clr--burgundy--hover);
}

.loader {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loader > img {
  width: 150px;
  transform: scale(1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}

/* ANIMATON CLASSES */

/* Text underline grow from middle */

.underline-grow {
  position: relative;
  color: var(--clr--purple);
}

.underline-grow::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--clr--purple);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.underline-grow:hover::before {
  transform: scaleX(1);
}

/* Header */

.header {
  width: 100vw;
  background-color: var(--clr--purple);
}

.header__logo__container {
  margin: 0 auto;
  width: 90%;
  max-width: 1440px;
  height: 160px;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__logo__container > * {
  width: 33%;
}

.header__logo__container > div:nth-child(3) {
  color: white;
  font-family: var(--ff--common);
  font-size: 12px;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.header__logo__container > div:nth-child(3) a {
  color: white;
  font-size: 14px;
}

.header__logo__container > div:nth-child(3) a:hover {
  color: var(--clr--burgundy);
}

.header__logo__container > div:nth-child(1) {
  color: white;
  font-family: var(--ff--common);
  font-size: 12px;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header__logo__container > div:nth-child(1) > a {
  color: white;
  font-size: 24px;
  margin-left: 20px;
  transition: 0.2s;
}

.header__logo__container > div:nth-child(1) > a:hover {
  transform: scale(1.2);
}

.header__logo {
  height: 100%;
  width: auto;
  object-fit: contain;
  cursor: pointer;
}

.header__menu {
  width: 100vw;
  background-color: white;
  display: flex;
  align-items: center;
  z-index: 20;
}
.purple-line {
  height: 2px;
  background-color: var(--clr--purple);
  width: 20%;
}

.header__menu__container {
  margin: 0 auto;
  display: flex;
  width: 80%;
  max-width: 1440px;
  padding: 20px 50px 15px 50px;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  position: relative;
  height: 100%;
}

.header__menu__container > a {
  color: var(--clr--purple);
  font-family: var(--ff--common);
  font-weight: 400;
  padding-bottom: 5px;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 1025px) {
  .header__menu__container > a {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1025px) {
  .header__menu__container > a {
    font-size: 10px !important;
  }
}

.header__clicked {
  border-bottom: solid var(--clr--purple) 2px;
}

/* Footer */

.footer {
  width: 100vw;
  height: 150px;
  padding: 40px;
  background-color: #272a2b;
}

.footer__container {
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.footer__container > div {
  display: flex;
  align-items: center;
  color: white;
  font-family: var(--ff--common);
}

.footer__container img {
  width: 80px;
  cursor: pointer;
}

.footer__container a {
  color: white;
  font-size: 24px;
  margin: 0 10px;
}

/* Home */

.home {
  width: 100vw;
}

.hero__img {
  max-width: 60%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
  display: block;
  /* filter: brightness(60%); */
}

.hero__slide {
  position: relative;
  display: flex;
  width: 100%;
  height: 78vh;
}

/* @media screen and (max-width: 600px) {
  .hero__slide {
    position: relative;
    display: flex;
    width: 100%;
    height: 40vh;
  }
} */

.hero__text {
  color: white;
  font-family: var(--ff--common);
  width: 50%;
  height: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  margin-bottom: 50px;
  background: rgb(85, 50, 130);
  background: -moz-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553282",endColorstr="#be3b7e",GradientType=1);
  /* background-color: var(--clr--purple); */
}

.hero__text > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero__logo {
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px;
}

.hero__title {
  font-size: 30px;
  font-weight: 600;
  max-width: 80%;
  margin-bottom: 30px;
}

.hero__description {
  font-size: 16px;
  font-weight: 400;
  max-width: 70%;
  margin-bottom: 30px;
  line-height: 24px;
}

.home__about {
  margin: 0 auto;
  padding: 100px 200px;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  text-align: justify;
}

.home__about li {
  margin-left: 22px;
  list-style-position: inside;
  list-style: disc;
  margin-bottom: 15px;
}

.home__about > img {
  width: 30%;
  object-fit: contain;
}

.home__about > div {
  width: 60%;
}

.home__about > div > * {
  margin-bottom: 30px;
}

.home__about > div > button {
  margin: 10px auto 0 auto;
}

.home__testimonials {
  margin: 0 auto;
  /* padding: 100px 100px 70px 100px;
  width: 100vw;
  max-width: 1440px; */
  background-color: var(--clr--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--ff--common);
  width: 100%;
  padding: 100px;
  max-width: 100%;
}

.home__testimonial {
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  height: 100%;
}

.home_testimonial_text {
  font-style: italic;
  color: white;

  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  /* text wrap */
  /* word-wrap: break-word;
  white-space: nowrap; */
  max-width: 1000px;
  overflow: hidden;
  font-family: var(--ff--common);
}

.home__testimonial > h2 {
  width: 80%;
  margin: 0 auto;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
  font-family: var(--ff--common);
}

@media screen and (max-width: 600px) {
  .home_testimonial_text {
    /* width: 300px; */
    font-size: 12px;
    line-height: 24px;
  }
  .home__testimonial {
    width: 100% !important;
    padding: 0 20px;
  }
}

.home__testimonial > h3 {
  width: 80%;
  margin: 0 auto;
  color: var(--clr--pink);
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  font-family: var(--ff--common);
}

.home__testimonial > img {
  max-width: 200px;
  object-fit: contain;
  margin-top: -30px;
}

.testimonials {
  margin: 0 auto;
  padding: 100px 100px 0 100px;
  width: 100vw;
  max-width: 1440px;
  background-color: var(--clr--purple);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--ff--common);
}

@media screen and (max-width: 600px) {
  .testimonials {
    width: 100%;
    max-width: 300px;
  }
}

.testimonial {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.testimonial > h1 {
  font-style: italic;
  color: white;
  width: 80%;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.testimonial > h2 {
  width: 80%;
  margin: 0 auto;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 20px;
}

.testimonial > h3 {
  width: 80%;
  margin: 0 auto;
  color: var(--clr--pink);
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
}

.testimonial > img {
  max-width: 200px;
  object-fit: contain;
  margin-top: -30px;
}

/* About */

.about {
  width: 100vw;
  min-height: 100vh;
}

.about__container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 80px 80px 80px;
}

.about__container > img {
  width: 10%;
  max-width: 150px;
  margin-bottom: 30px;
  object-fit: contain;
}

.about__container > h1 {
  margin-bottom: 30px;
  max-width: 60%;
}

.about__container > h2 {
  margin-bottom: 30px;
  max-width: 60%;
  text-align: center;
}

.about__container > p {
  margin-bottom: 20px;
  max-width: 60%;
  text-align: center;
  white-space: pre-wrap;
}

.home_para {
  margin-bottom: 20px;
  max-width: 100%;
  text-align: left;
  white-space: pre-wrap;
}

.about__container > button {
  margin-top: 40px;
  margin-bottom: 40px;
}

/* Contact */

.contact {
  width: 100vw;
  min-height: 100vh;
}

.contact__container {
  margin: 0 auto;
  max-width: 1440px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 50px 80px 80px 80px;
  margin-bottom: 50px;
}

.contact__spacer {
  height: 100vh;
}

.contact__info > p {
  color: var(--clr--burgundy);
  font-family: var(--ff--common);
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact__info > p > a {
  color: var(--clr--burgundy);
}

.contact__info > p > a:focus {
  color: var(--clr--burgundy);
}

.contact__info > p > a:hover {
  color: black;
}

.contact__info > p > span {
  color: black;
}

.contact__form {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.contact__form > h1 {
  width: 100%;
  text-align: center;
}

.contact__form > h2 {
  width: 100%;
  text-align: center;
  font-family: var(--ff--common);
  font-style: italic;
  color: grey;
  margin-top: 15px;
}

.contact__form > h3 {
  width: 100%;
  text-align: center;
  font-family: var(--ff--common);
  font-style: italic;
  color: red;
  margin-top: 15px;
}

.contact__form > h2 > span {
  color: red;
}

.contact__form > label {
  display: flex;
  flex-direction: column;
}

.contact__form > label > div {
  margin-top: 20px;
  margin-bottom: 10px;
}

.contact__form > label > div > span {
  color: red;
}

.contact__form > label > input {
  padding: 10px;
  font-family: var(--ff--common);
}

.contact__form > label > textarea {
  padding: 10px;
  font-family: var(--ff--common);
  margin-bottom: 30px;
}

.contact__confirmation {
  margin: 40px 0;
}

/* Gallery */

.gallery {
  min-height: 80vh;
}

.gallery__container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 680px;
}

.snapwidget-widget {
  height: auto;
}

/* .gallery__container > img {
  width: 23%;
  height: 300px;
  object-fit: cover;
  margin: 0.4%;
}

.gallery__container > img:hover {
  filter: brightness(0.5);
  cursor: pointer;
}

.gallery__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery__modal > img {
  max-width: 90%;
  max-height: 90%;
}

.gallery__modal > i {
  font-size: 30px;
  position: fixed;
  top: 50px;
  right: 50px;
  color: white;
  cursor: pointer;
} */

/* Past */
.past {
  min-height: 100vh;
}

.past__container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.past__container > div {
  width: 23%;
  height: 300px;
  object-fit: contain;
  margin: 0.4%;
  background-color: black;
}

.past__container > div > img {
  width: 99%;
  height: 99%;
  object-fit: contain;
  margin: 0.4%;
}

.past__container > div:hover {
  filter: brightness(0.5);
  cursor: pointer;
}

.past__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.past__modal > img {
  max-width: 90%;
  max-height: 90%;
}

.past__modal > p {
  font-size: 30px;
  position: fixed;
  top: 50px;
  right: 50px;
  color: white;
  cursor: pointer;
  font-family: var(--ff--common);
}
/* Admin */

.admin {
  min-height: 100vh;
}

.admin__container {
  padding-top: 50px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin__container > label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.admin__container > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.admin__container > div > input {
  color: black;
  margin: 0 0 30px 0;
}

.admin__container > div > h2 {
  color: black;
  margin-top: 15px;
}

.admin__container input,
.admin__container select {
  color: black;
  margin: 30px 0;
}

.admin__password {
  text-align: center;
  display: flex;

  flex-direction: column;
  align-items: center;
}

.admin__password > * {
  margin-bottom: 20px;
}

.admin__deleteModal {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: center;

  flex-wrap: wrap;
}

.admin__deleteModal > div {
  width: 23%;
  height: 300px;
  margin: 0.4%;
  background-color: black;
  position: relative;
}

.admin__deleteModal > div > img {
  width: 99%;
  height: 99%;
  object-fit: contain;
  margin: 0.4%;
}

.admin__deleteModal > div > button {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
}

.admin__hero__container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.admin__hero {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 1%;
  text-align: center;
  padding: 10px;
  border: solid 2px black;
}

.admin__hero > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border: solid black 2px;
}
.admin__hero > label {
  width: 100%;
}

.admin__hero > label > h2 {
  margin: 5px 0;
}

.admin__hero > label > input {
  margin: 5px 0;
  width: 100%;
}

.admin__hero > label > textarea {
  font-family: var(--ff--common);
  margin: 5px 0;
  width: 100%;
  height: 100px;
}

.admin__about {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row !important;
  margin-bottom: 30px;
}

.admin__about > div {
  min-height: 500px;
  padding: 20px;
  border: solid black 2px;
  width: 30%;
  margin: 0 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.admin__about > div > h2 {
  margin: 5px 0;
}

.admin__about > div > input {
  margin: 5px 0;
  width: 100%;
}

.admin__about > div > textarea {
  font-family: var(--ff--common);
  margin: 5px 0;
  width: 100%;
  height: 100px;
}

/* events */
.events {
  min-height: 100vh;
}

.events__container {
  padding-top: 50px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  margin-bottom: 80px;
}

.event {
  position: relative;
  width: 25%;
  margin: 20px;
}

.event > img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.event > img:hover {
  cursor: pointer;
  filter: brightness(0.5);
}

.event > h1 {
  padding: 10px;
  width: 100%;
  text-align: center;
  bottom: 0;
  left: 0;
  position: absolute;
  font-family: var(--ff--common);
  color: white;
  /* background: rgb(85, 50, 130);
  background: -moz-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553282",endColorstr="#be3b7e",GradientType=1); */
  background-color: var(--clr--burgundy);
  z-index: 2;
}

.events__dropdown {
  position: absolute;
  background-color: white;
  padding: 10px 20px;
  display: flex;
  top: 27px;
  /* top: 30px; */
  left: -65%;

  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  z-index: 10;
  text-align: center;
  width: fit-content;
  white-space: nowrap;
  font-size: 16px;
}

.events__dropdown > a {
  margin: 10px 0;
  padding-bottom: 10px;
  color: var(--clr--purple);
}

.events__dropdown > a:hover {
  color: black;
}

.events__dropdown > a:focus {
  color: var(--clr--purple);
}

/* Videos */

/* .videos {
  min-height: 100vh;
} */

.videos__container {
  padding-top: 0px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
  justify-content: center;
}

.videos__container > iframe {
  margin: 20px;
  width: 40%;
  height: 300px;
}

.videos__showreel__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.videos__showreel__container > video {
  height: 300px;
  width: 500px;
}

.videos__showreel__container > H1 {
  margin: 50px 0;
}

/* Video Tesimonials */

.videoTestimonials {
  min-height: 100vh;
  width: 100vw;
  background: rgb(85, 50, 130);
  background: -moz-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(85, 50, 130, 1) 0%,
    rgba(190, 59, 126, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#553282",endColorstr="#be3b7e",GradientType=1);
}

.videoTestimonials__container {
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  text-align: center;
}

/* RESPONSIVE */

@media (max-width: 768px) {
  .btn-burgundy {
    font-size: 14px;
  }
  .txt-header-purple {
    font-size: 24px;
  }

  .txt-header-white {
    font-size: 24px;
  }

  .txt-description-black-bold {
    font-size: 14px;
    line-height: 20px;
  }

  .txt-description-black {
    font-size: 14px;
    line-height: 20px;
  }
  /* Header */
  .header__logo__container > div:nth-child(1) > a,
  .header__logo__container > div:nth-child(3) {
    display: none;
  }
  .header__logo__container {
    justify-content: center;
  }
  .header__logo__container > * {
    width: fit-content;
  }
  .purple-line {
    display: none;
  }
  .header__menu__container {
    width: 100%;
    padding: 20px 10px 15px 10px;
    font-size: 12px;
  }

  /* Home */
  .hero__slide {
    position: relative;
    height: 50vh;
  }
  .hero__img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    filter: brightness(60%);
  }

  .hero__text {
    position: absolute;
    color: white;
    font-family: var(--ff--common);
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    margin-bottom: 15px;
    background: none;
    text-align: center;
  }

  .hero__logo {
    display: none !important;
    height: 80px;
  }
  .hero__title {
    font-size: 18px;
    margin-top: 50px;
  }
  .hero__description {
    font-size: 14px;
    line-height: 18px;

    margin-bottom: 15px;
  }
  .home__about {
    flex-direction: column;
    padding: 50px 30px;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .home__about > img {
    display: none;
  }
  .home__about > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .home__about li {
    list-style: none;
  }
  .home__testimonials {
    padding: 80px 30px 40px 30px;
  }
  .home__testimonial {
    width: 60%;
  }
  .home__testimonial > h1 {
    width: 60%;
    font-size: 16px;
    max-height: 30vh;
    overflow: hidden;
  }

  .testimonial {
    width: 100%;
  }
  .testimonials {
    padding: 80px 30px 40px 30px;
  }

  /* Footer */
  .footer__container > div {
    font-size: 10px;
  }

  /* About */
  .about__container {
    padding: 20px 30px 50px 30px;
    text-align: center;
  }
  .about__container > h1 {
    max-width: 90%;
  }

  .about__container > h2 {
    max-width: 90%;
  }

  .about__container > p {
    max-width: 90%;
    text-align: justify;
  }

  /* Contact */
  .contact__container {
    flex-direction: column;
    align-items: center;
    padding: 20px 30px 50px 30px;
  }

  .contact__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .contact__form {
    width: 90%;
    align-items: center;
  }
  .contact__form > label > div {
    width: 100%;
    text-align: center;
  }
  /* Gallery */
  /* .gallery__container > img {
    width: 47%;
    height: 300px;
    object-fit: cover;
    margin: 1%;
    
  } */

  /* events */

  .event {
    width: 40%;
    margin: 10px;
  }
  .events__dropdown {
    left: -70%;
    font-size: 10px;
    padding: 10px;
  }
  .events__dropdown > a {
    margin: 5px 0;
    padding-bottom: 10px;
  }

  /* Videos */
  .videos__container > iframe {
    margin: 20px;
    width: 90%;
    height: 250px;
  }
  .videos__showreel__container > video {
    width: 90%;
  }
  /* Past */
  .past__container > img {
    width: 48%;
    height: 200px;
  }
}

/* WhatsApp */

/* for desktop */
.whatsapp_float {
  display: none;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    display: block;
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
/* 
@media screen and (max-width: 600px) {
  .testimonials {
    width: 100%;
    max-width: 200px;
  }

  .testimonial {
    width: 250px !important;
    background: red;
  }
} */

.carousel.carousel-slider .control-arrow {
  font-size: 60px !important;
  padding: 10px !important;
}

.share-overlay .icon.twitter {
  /* background-image: url("./assets/img/x.png") !important; */
  display: block !important;
  background-image: url("./assets/img/x.png") !important;
  margin-right: 10px !important;
}

@media screen and (max-width: 600px) {
  .mobile_testimonial {
    max-width: 100% !important;
    width: 100% !important;
    padding: 50px 30px 40px 30px;
  }

  .mobile_testimonial h1 {
    max-width: 100% !important;
    width: 100% !important;
  }
}
